import {isAncestorOf} from "./site";

export class Navbar {
    private readonly navbar: HTMLElement;
    private readonly navbarBody: HTMLElement;
    private tabIndex = 0;
    private activeTab: HTMLElement | null = null;
    private readonly tabs: NodeListOf<HTMLElement>;
    private activeTabContents: HTMLElement | null = null;
    private readonly tabContents: NodeListOf<HTMLElement>;
    private navbarBodyContainer: HTMLElement;

    expand(tab: HTMLElement) {
        // Move to new tab
        this.moveToTab(tab);
        // Update tab selection
        tab.classList.add('selected');
        // Update active tab
        this.activeTab = tab;
    }

    collapse() {
        // Close the navbar
        this.navbarBody.style.height = '0px';
        this.navbar.style.borderColor = 'transparent';
        // Update tab selection
        this.activeTab?.classList.remove('selected');
        // Update active tab
        this.activeTab = null;
        this.activeTabContents = null;
    }

    switchTabs(to: HTMLElement) {
        // Move to new tab
        this.moveToTab(to);
        // Update tab selection
        to.classList.add('selected');
        this.activeTab!.classList.remove('selected');
        // Update active tab
        this.activeTab = to;
    }

    private moveToTab(tab: HTMLElement) {
        this.moveToIndex(Array.prototype.slice.call(this.tabs).indexOf(tab));
    }

    private moveToIndex(tab: number): void {
        this.activeTabContents = this.tabContents[tab];
        this.tabIndex = tab;
        // Move to correct tab.
        this.navbarBodyContainer.style.left = "-" + this.tabIndex + "00%";
        // Open the navbar.
        this.navbarBody.style.height = String(this.activeTabContents.getBoundingClientRect().height) + 'px';
        this.navbar.style.borderColor = 'white';
    }

    /**
     * @returns `true` if the NavBar is expanded, `false` otherwise.
     */
    isOpen() {
        return this.activeTab !== null;
    }

    /**
     * @returns The currently active tab.
     */
    currentTab() {
        return this.activeTab;
    }

    goToTab(index: number) {
        if (!this.isOpen())
            // This is set on a delay to avoid having it immediately closed by the document click handler.
            setTimeout(()=>{this.expand(this.tabs[index])}, 1);
    }

    static processDocumentClick(navbar: Navbar, clickEvent: MouseEvent) {
        if (navbar.isOpen() && !isAncestorOf(navbar.navbar as HTMLDivElement, clickEvent.target as HTMLElement))
            navbar.collapse();
    }

    static processWindowResize(navbar: Navbar) {
        if (navbar.isOpen())
            navbar.navbarBody.style.height = String(navbar.tabContents[navbar.tabIndex].getBoundingClientRect().height) + 'px';
    }

    private processButtonPress(item: HTMLElement) {
        if (!this.isOpen())  // Expand if not already open.
            this.expand(item);
        else if (this.currentTab() !== item)  // Switch between two tabs.
            this.switchTabs(item);
        else  // Close opened tab.
            this.collapse();
    }

    constructor(document: Document) {
        this.navbar = document.querySelector('.navbar')!;
        this.navbarBody = document.querySelector('.navbar_body')!;
        this.navbarBodyContainer = document.querySelector('.navbar_body_container')!;
        this.tabContents = document.querySelectorAll('.navbar_body_container_entry');
        this.tabs = document.querySelectorAll('.navbar_panel_container_button');
        this.tabs.forEach((tab: HTMLElement) => {
            tab.addEventListener('click', () => {this.processButtonPress(tab)});
        });
        document.querySelectorAll('.close_navbar_on_click')?.forEach((button: Element) => {
            button.addEventListener('click', () => {
                if (this.isOpen()) {
                    this.collapse();
                }
            })
        });
    }
}